import { type User } from "firebase/auth"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth"
import { useSignOut } from "react-firebase-hooks/auth"

import { auth } from "../firebase"
import type { CustomClaims } from "../types/auth"

interface AuthContextType {
  user: User | undefined
  loading: boolean
  claims: CustomClaims | undefined
  error: Error | undefined
  signInWithGoogle: () => Promise<void>
  signOut: () => Promise<boolean>
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  loading: false,
  error: undefined,
  claims: undefined,
  signInWithGoogle: async () => {},
  signOut: () => Promise.resolve(false),
})

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, userLoading, userError] = useAuthState(auth)
  const [signOut, signOutLoading, signOutError] = useSignOut(auth)
  const [claimsLoading, setClaimsLoading] = useState<boolean>(true)
  const [claimsError, setClaimsError] = useState<Error | undefined>()
  const [claims, setClaims] = useState<CustomClaims | undefined>()
  const [signInWithGoogle, , googleLoading, googleError] =
    useSignInWithGoogle(auth)

  const _refreshClaims = useCallback(async (user: User | undefined | null) => {
    if (!user) {
      setClaims(undefined)
      setClaimsLoading(false)
      return
    }
    setClaimsLoading(true)
    try {
      const tokenResult = await user.getIdTokenResult()
      setClaims(tokenResult.claims as unknown as CustomClaims)
    } catch (err) {
      setClaimsError(Error("Error fetching claims"))
    } finally {
      setClaimsLoading(false)
    }
  }, [])

  useEffect(() => {
    void _refreshClaims(user)
  }, [_refreshClaims, user])

  const _signInWithGoogle = useCallback(async () => {
    await signInWithGoogle(
      [
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
      ],
      {
        prompt: "select_account",
      },
    )
  }, [signInWithGoogle])

  const value: AuthContextType = {
    user: user ?? undefined,
    loading: userLoading || claimsLoading || signOutLoading || googleLoading,
    error: userError || signOutError || claimsError || googleError,
    claims: claims,
    signOut,
    signInWithGoogle: _signInWithGoogle,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}
