import { useAuth } from "../hooks/useAuth"

interface Props {
  children: React.ReactNode
}

export const AuthGuard: React.FC<Props> = ({ children }) => {
  const { user, loading, error, claims, signOut, signInWithGoogle } = useAuth()
  // TODO(arie): Make different pages instead
  const SignOutComponent = () => {
    return (
      <div>
        <br />
        <button onClick={signOut}>Sign Out</button>
      </div>
    )
  }
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return (
      <div>
        Error: {error.message}
        <SignOutComponent />
      </div>
    )
  }
  if (!user || !claims) {
    return (
      <>
        <h1>Sign In Page</h1>
        <button onClick={signInWithGoogle}>Sign in with Google</button>
      </>
    )
  }
  if (!claims.isApproved) {
    return (
      <div>
        <div>😅 You are on the waitlist...</div>
        <SignOutComponent />
      </div>
    )
  }
  return (
    <div>
      {children}
      <SignOutComponent />
    </div>
  )
}

export default AuthGuard
