import { StrictMode } from "react"

import AuthGuard from "./components/AuthGuard"
import ThemeButton from "./components/ThemeButton"
import { AuthProvider, useAuth } from "./hooks/useAuth"

const Welcome = () => {
  const { user, error } = useAuth()
  /* TODO(arie): use pages instead*/
  if (error) {
    console.error(error)
  }
  const profilePicture = user?.photoURL ? (
    <img
      width={64}
      height={64}
      src={user.photoURL}
      alt="Profile Picture"
      referrerPolicy="no-referrer"
    />
  ) : null
  return (
    <>
      <h1>Gadol</h1>
      <h2>Welcome to {import.meta.env.VITE_FIREBASE_PROJECT_ID}</h2>
      {profilePicture}
      <div>
        <b>{user?.displayName}</b>
      </div>
      <div>{user ? user.email : "None"}</div>
    </>
  )
}

function App() {
  return (
    <StrictMode>
      <AuthProvider>
        <ThemeButton />
        <AuthGuard>
          <Welcome />
        </AuthGuard>
      </AuthProvider>
    </StrictMode>
  )
}

export default App
